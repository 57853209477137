import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';



const content1 = {
  title: "Responsibilities:",
  content: [
          "- Trade crypto and forex currency pairs with the firm's capital",
          "- Continuously improve your trading skills through training and mentorship",
          "- Research and analyze market data to make informed trading decisions",
          "- Manage risk through diligent trade management and risk management strategies",
          "- Operate from anywhere in the world with a high-speed internet connection",
    ]
}

const content2 = {
  title: "Qualifications:",
  content: [
            "- No prior experience is required, but a willingness to learn and an entrepreneurial spirit is essential",
            "- Strong motivation and drive to succeed as a trader",
            "- Willingness to develop a strong understanding of financial markets and risk management",
            "- Strong analytical skills and the ability to make quick decisions in a fast-paced environment",
            "- Ability to work in a fast-paced and mentally challenging environment",
            "- Bachelor’s degree in finance, economics, or a related field is preferred but not required",

    ]
}

const companyURL = "https://mavericktrading.com/";


function AppTraderKane2() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);

  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="REMOTE" salary="$200k - $250k / yr" company="Maverick Trading" companyURL={companyURL} />
          <LanguageOptionsTraders company="Maverick Trading" title="Forex / Cryptocurrency Trader" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppTraderKane2;
