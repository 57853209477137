import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptions from './components/LanguageOptions';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';

const content1 = {
  title: "Key Responsibilities:",
  content: [
           "1. Smart Contracts:",
           "- Develop and maintain secure smart contracts to support BingX’s products.",
           "- Regularly update contracts to ensure top security and performance.",
           "2. Blockchain Integration:",
           "- Connect blockchain features across different platforms to enhance our ecosystem.",
           "- Work closely with developers to ensure seamless functionality across web and mobile.",
           "3. Security & Optimization:",
           "- Monitor and improve blockchain applications to keep them safe and efficient.",
           "- Troubleshoot and resolve issues quickly to maintain high performance.",
           "4. Feature Development:",
           "- Collaborate with the team to create new blockchain features that improve user experience and product quality.",
      ]
}

const content2 = {
  title: "Requirements:",
  content: [
           "- Experience: Proven background as a Blockchain Engineer, with skills in smart contract programming (e.g., Solidity, Rust).",
           "- Skills: Deep knowledge of blockchain platforms and multi-chain integration.",
           "- Security Focus: Strong understanding of blockchain security.",
           "- Communication: Excellent problem-solving skills and teamwork abilities.",

      ]
}

const companyURL = "http://bingx.com";


function App() {

  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])


  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote / On-site, US" salary="$160,000 - $200,000/Yr" company="BingX" companyURL={companyURL} />
          <LanguageOptions company="BingX" title="Blockchain Engineer – Remote" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default App;
