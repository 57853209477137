import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.css';
import { useAppContext } from '../Provider/AppProvider';
import { URL, UNIQUE } from '../config/env.js';

const SignUp = ({ callback }) => {
  const { state, setData } = useAppContext();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust this breakpoint if needed
    };

    handleResize(); // Check screen size on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      company: localStorage.getItem('company') || state.data.companyURL,
    });
  }, [state.data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const onSubmit = async () => {
    callback();
    await axios.post(`${URL}/starttest`, { ...formData, unique: UNIQUE });
  };

  if (isMobile) {
    return (
      <div className="modal-overlay">
        <div className="modal-content text-center">
          <h2>We don't recommend you to proceed on mobile</h2>
          <p>Please try using a PC or laptop for a better experience.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Let's Get to Know You!</h2>
        <p>
          Please enter your full name and email address so we can keep track of your results and contact you if needed.
        </p>
        <form onSubmit={handleSubmit} className="text-left items-center">
          <div className="w-full">
            <p className="ml-4 mb-1 mt-2">Full Name</p>
            <input
              className="w-full"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="w-full">
            <p className="ml-4 mb-1 mt-2">Email</p>
            <input
              className="w-full"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="submit-button">Start the Test</button>
        </form>
        <button className="close-button">&times;</button>
      </div>
    </div>
  );
};

export default SignUp;
