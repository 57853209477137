import React from 'react';
import { Link } from "react-router-dom";
import './StepBar.css'

const StepTraderBar = ({ className, index }) => {
  return (
    <div className={`step-bar-container flex items-center justify-between ${className}`}>
      <Link to="/video-questions/create/dkkssjg95xngk83754kl6k3h2llgks004" className={`circle ${index == 1 ? "active-step" : "passed-step"}`}>1</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/dfaelkfjelgj885kljk009dkk4994k4" className={`circle ${index == 2 ? "active-step" : index == 3? "passed-step" : ""}`}>2</Link>
      <div className="line grow" style = {{width: "500px"}}></div>
      <Link to="/video-questions/create/dkgklsie8395k3k5j65462l3kls0gdkksk" className={`circle ${index == 3 ? "active-step" : ""}`}>3</Link>

    </div>
  );
};

export default StepTraderBar;
