import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';


const content1 = {
  title: "Primary responsibilities:",
  content: [
           "- Executing algorithmic trading strategies in accordance with the trading desk's objectives",
           "- Monitoring and reconciling trading positions consistently throughout the shift",
           "- Performing start of day position and activity reconciliations",
           "- Performing post trade analysis on a variety of algorithmic trading strategies",
           "- Completing end of day PnL and position reporting",
           "- Communicating with trading exchanges and internal technology groups regarding trading system issues",
           "- Managing and reporting any potential compliance issues",
           "- Assisting quantitative traders in performing research for new trading strategies",

      ]
}

const content2 = {
  title: "Requirements:",
  content: [
           "- Bachelor’s degree in business/finance, statistics, mathematics, engineering, or related field",
           "- 0-2 years’ work experience; financial experience preferred, but not required",
           "- Strong analytical, quantitative, and math skills",
           "- Ability to work independently and successfully manage multiple tasks in a complex and fast-paced environment",
           "- Strong organizational skills and attention to detail",
           "- Excellent written and verbal communication skills",
           "- Excellent computer proficiency, including Excel and other MS office applications",
           "- Demonstrated strong work ethic and team player focused on contributing to the success of the trading desk",
           
      ]
}

const companyURL="https://www.eagleseven.com/";

function AppTraderCat() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="REMOTE" salary="$180,000 - $250,000/Yr" company="Eagle Seven" companyURL={companyURL} />
          <LanguageOptionsTraders company="Eagle Seven" title="Remote Forex / Cryptocurrency Trader" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppTraderCat;
