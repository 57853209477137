import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';



const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- Develop and execute market-making strategies across various crypto assets to provide liquidity and enhance market efficiency.",
          "- Continuously monitor and analyze market conditions, adjusting trading strategies to optimize profitability and minimize risks.",
          "- Utilize advanced quantitative models and algorithms to make informed trading decisions.",
          "- Collaborate closely with the technology team to develop and improve trading systems and tools.",
          "- Manage the trading book, ensuring accurate and timely execution of trades and proper risk management practices.",
          "- Implement and oversee risk control strategies to mitigate potential market and operational risks. ",
          "- Provide real-time market insights and analysis to the investment management team.",
          "- Maintain compliance with industry regulations and internal policies, ensuring all trading activities are conducted ethically and legally.",
          "- Participate in the development of new trading strategies and contribute to the overall growth of the trading desk.",

    ]
}

const content2 = {
  title: "Key Requirements:",
  content: [
            "- Proven experience in quantitative trading strategies in HFT with track record.",
            "- Strong skills in mathematics, statistics, and programming (Python, C++, etc.).",
            "- Ability to thrive in a fast-paced trading environment.",
            "- Deep understanding of financial markets and market microstructure.",
    ]
}

const companyURL = "https://albertbow.com/";


function AppTraderKane() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);


  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);

  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="REMOTE" salary="$150K/yr - $280K/yr" company="Albert Bow" companyURL={companyURL} />
          <LanguageOptionsTraders company="Albert Bow" title="Quantitative Trader" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppTraderKane;
