import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsPortfolioManager from './components/LanguageOptionsPortfolioManager';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';



const content1 = {
  title: "Responsibilities:",
  content: [
          "- Develop, manage, and optimize cryptocurrency portfolios for clients or the company",
          "- Identify, evaluate, and select appropriate assets (coins, tokens, DeFi products, etc.) based on market analysis",
          "- Maintain a balanced portfolio with diversified risk and return profiles",
          "- Implement hedging and risk management strategies to mitigate volatility and protect capital",
    ]
}

const content2 = {
  title: "Qualifications:",
  content: [
            "- Strong understanding of blockchain technology and decentralized finance (DeFi)",
            "- Proficiency in using crypto exchanges, wallets, and portfolio management tools (e.g., Binance, Coinbase, Kraken, etc.)",
            "- Advanced knowledge of risk management strategies and tools",
            "- Familiarity with crypto analytics platforms (e.g., Glassnode, CoinGecko, CoinMarketCap, etc.)",

    ]
}

const companyURL = "https://bitwiseinvestments.com/";


function AppKaneBitwisePortfolioManger() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);

  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="REMOTE" salary="$200k / yr" company="Bitwise Asset Management" companyURL={companyURL} />
          <LanguageOptionsPortfolioManager company="Bitwise Asset Management" title="Senior Crypto Portfolio Manager" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppKaneBitwisePortfolioManger;
