import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsBusiness from './components/LanguageOptionsBusiness';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';



const content1 = {
  title: "Key Responsibilities:",
  content: [
           "- Market Research: Study crypto trends, regulations, and competitors to identify opportunities.",
           "- Investment Analysis: Evaluate blockchain projects and provide recommendations for growth.",
           "- Build Partnerships: Develop relationships with key players in the crypto space, including companies, influencers, and communities.",
           "- Lead Teams: Guide and mentor analysts and business development specialists.",
           "- Track Performance: Monitor progress and adjust strategies to meet goals.",
           "- Collaborate: Work with marketing, product, and tech teams to align efforts.",

      ]
}

const content2 = {
  title: "Requirements:",
  content: [
           "- Experience: 8+ years in crypto, blockchain, or fintech roles focusing on business development or analysis & Proven success in partnerships and leading teams.",
           "- Skills: Strong understanding of crypto markets and DeFi & Excellent analytical, strategic, and communication skills.",
           "- Education: Degree in Finance, Economics, or Business (MBA is a plus).",
           "- Attributes: Proactive, results-oriented, and well-connected in the blockchain industry.",
      ]
}

const companyURL="https://bingx.com/";

function AppBusinessDevelopment() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="New York, US" salary="$200,000 - $250,000/Yr" company="Bingx" companyURL={companyURL} />
          <LanguageOptionsBusiness company="Bingx" title="Crypto Analyst & Business Development Director" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppBusinessDevelopment;
